<template>
  <HostLayoutContainer class="client" style="margin-top: 25px">
    <HostLayoutItem>
      <div class="core__title" id="azure-introduce">认识 Microsoft Azure</div>
      <div class="subhead">一个不断增长的集成云服务集合</div>
      <div class="subhead">助您加快发展步伐，提高工作效率，节省运营成本</div>
      <div style="width: 1200px; margin: 30px auto">
        <img src="/assets/product/intro-azure.svg" alt="">
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  
})
</script>

<style lang="scss" scoped>
.core__title {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D253E;
  line-height: 45px;
  letter-spacing: 2px;
}
.subhead{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D253E;
  line-height: 30px;
  letter-spacing: 2px;
}

.matrix__row {
  display: flex;
  justify-content: space-around;
}
</style>
