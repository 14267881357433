<template>
  <HostLayoutContainer class="client">
    <HostLayoutItem>
      <div class="core__title" id="world-and-china">全球版 Azure&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;and&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;中国版 Azure</div>
      <div style="width: 1200px; margin: 0 auto">
        <img src="/assets/product/compare.svg" alt="">
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({

})
</script>

<style lang="scss" scoped>
.core__title {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D253E;
  line-height: 45px;
  letter-spacing: 2px;
  margin-bottom: 20px;
}

.matrix__row {
  display: flex;
  justify-content: space-around;
}
</style>
