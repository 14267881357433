<template>
  <Banner />
  <AzureIntroduce />
  <SecurityBelieve />
  <GlobalMap />
  <HighLight />
  <Compare />
  <OtherProduct />
  <Footer />
  <CopyRight />
</template>

<script>
import Banner from '@/components/banner.vue'
import AzureIntroduce from '@/components/product/microsoft/azure-introduce.vue'
import SecurityBelieve from '@/components/product/microsoft/security-believe.vue'
import GlobalMap from '@/components/product/microsoft/global-map.vue'
import HighLight from '@/components/product/microsoft/high-light.vue'
import Compare from '@/components/product/microsoft/compare.vue'
import OtherProduct from '@/components/product/microsoft/other-product.vue'

import Footer from '@/components/footer.vue'
import CopyRight from '@/components/copy-right.vue'

export default {
  name: 'Home',
  components: {
    Banner,
    AzureIntroduce,
    SecurityBelieve,
    GlobalMap,
    HighLight,
    Compare,
    OtherProduct,
    CopyRight,
    Footer
  }
}
</script>
