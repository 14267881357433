<template>
  <HostLayoutContainer class="client" style="">
    <HostLayoutItem>
      <div class="core__title" id="global">Azure 技术遍布全球</div>
      <div class="subhead">在中国，由世纪互联运营，数据中心 BGP 接入，支持异地复制</div>
      <div style="width: 1200px; margin: 0 auto">
        <img src="/assets/product/global-map.svg" alt="">
      </div>
    </HostLayoutItem>
  </HostLayoutContainer>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({

})
</script>

<style lang="scss" scoped>
.core__title {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D253E;
  line-height: 45px;
  letter-spacing: 2px;
}
.subhead{
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0D253E;
  line-height: 45px;
  letter-spacing: 2px;
}

.matrix__row {
  display: flex;
  justify-content: space-around;
}
</style>
